import React, { Suspense, useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AccessDenied from '../components/UI/access-denied';
import AppSpinner from '../components/UI/spinner/AppSpinner';
import { routes } from './routes/routes';

import { loadAppointmentsFormats } from '../store/doctors/doctorsConfigurationsSlice';
import { loadJawTypes } from '../store/doctors/doctorsSlice';
import { myProfileDetails } from '../store/signup/signupSlice';
import {
	selectLoggedInUser,
	setActiveAccount,
	setBranch,
	setLoggedInUser,
} from '../store/globalSlice';
import { getAllBranches, submitBranchListRequest } from '../store/commonSlice';

import { getRouteElements } from '../utils/routing.utils';
import { isValidArray } from '../utils/globals.utils';
import { submitAccountsRequest } from '../store/calendarSlice';

const DashboardRouter = (props) => {
	const [activeRoutes, setActiveRoutes] = useState([]);
	const [first, setFirst] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector(selectLoggedInUser);
	const allBranches = useSelector(getAllBranches);

	const handleUserAuth = (res) => {
		if (res.data.responseCode !== 200) {
			localStorage.removeItem('authToken');
			history.push('/auth/sign-in');
		} else {
			const userDetails = {
				...res.data.responseData.UserDetails,
				...res.data.responseData.preferedDetail,
				...res.data.responseData?.practiceDetail,
			};
			dispatch(setLoggedInUser(userDetails));
			dispatch(setActiveAccount(res?.data?.responseData?.UserDetails?.id));
			const apiData = { doctor_id: res?.data?.responseData?.UserDetails?.id };
			dispatch(submitAccountsRequest(apiData));
		}
	};

	useEffect(() => {
		if (isValidArray(allBranches) && user && !first) {
			const branch = allBranches.find(
				(branch) => branch?.id === user?.branch_id,
			);
			if (branch) {
				dispatch(setBranch({ branch: branch }));
				setFirst(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allBranches, user]);

	useEffect(() => {
		dispatch(myProfileDetails()).then((res) => {
			handleUserAuth(res);
			dispatch(loadAppointmentsFormats());
			dispatch(loadJawTypes());
			dispatch(submitBranchListRequest());
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const mappedRoutes = routes.map((route) =>
			!route.role ||
			route.role.length <= 0 ||
			user?.roles?.some((role) => route?.role?.includes(role))
				? route
				: { ...route, component: AccessDenied },
		);
		const activeRoutes = getRouteElements(mappedRoutes);
		setActiveRoutes(activeRoutes);
	}, [user]);

	return (
		<Switch>
			<Suspense fallback={<AppSpinner />}>{activeRoutes}</Suspense>
		</Switch>
	);
};

export default DashboardRouter;
