import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { IDLE, PENDING } from '../../assets/conf/configurations';
import {
	fetchNextDoctorAppointments,
	fetchNextSharedAppointments,
	fetchNextWaitingListAppointments,
	fetchPriceList,
	otherCasesAppointments,
	createAppointment,
	getAppointmentById,
	postJoinAccountRequest,
} from '../../services/doctors/appointment.service';
import {
	appoinmentAndScanList,
	specialitiesList,
	waitingList,
} from '../../services/auth.service';

import { fetchJawTypes } from '../../services/doctors/doctors.service';
import { getAppointmentStatuses } from '../../services/doctors/appointment-statuses.service';

import { createCalendarEventFromAppointment } from '../../utils/calendar.utils';
import { getResponseTime, toastSuccess } from '../../utils/globals.utils';

export const doctorSlice = createSlice({
	name: 'doctors',

	initialState: {
		error: {},
		createAppointmentLoading: IDLE,
		createAppointmentFinished: false,
		appointmentsLoading: 'idle',
		sharedAppointmentsLoading: 'idle',
		waitingListAppointmentsLoading: 'idle',
		appointments: [],
		sharedAppointments: [],
		waitingListAppointments: [],
		filteredAppointments: [],
		filteredSharedAppointments: [],
		filteredWaitingListAppointments: [],
		specialtiesListLoading: 'idle',
		specialtiesListData: [],
		appointmentsListLoading: 'idle',
		appointmentsListData: [],
		waitingListLoading: 'idle',
		waitingListData: null,
		otherCaseAppointmentsLoading: 'idle',
		otherCaseAppointmentsData: null,
		priceListLoading: false,
		priceListData: null,
		joinAccountLoading: false,
		joinAccountData: null,
	},

	reducers: {
		fetchAppointmentsLoading(state, action) {
			if (state.appointmentsLoading === 'idle') {
				state.appointmentsLoading = 'pending';
			}
		},
		fetchAppointmentsSucceed(state, action) {
			state.appointmentsLoading = 'idle';
			state.appointments = action.payload;
			state.filteredAppointments = action.payload;
		},
		fetchAppointmentsFailed(state, action) {
			if (state.appointmentsLoading === 'pending') {
				state.appointmentsLoading = 'idle';
				state.error = action.payload;
			}
		},
		loadSpicialistListLoading(state, action) {
			if (state.specialtiesListLoading === 'idle') {
				state.specialtiesListLoading = 'pending';
			}
		},

		loadSpicialistListSucceed(state, action) {
			state.specialtiesListLoading = 'idle';
			state.specialtiesListData = action.payload;
		},

		loadSpicialistListFailed(state, action) {
			if (state.specialtiesListLoading === 'pending') {
				state.specialtiesListLoading = 'idle';
				state.error = action.payload;
			}
		},

		fetchSharedAppointmentsLoading(state, action) {
			if (state.sharedAppointmentsLoading === 'idle') {
				state.sharedAppointmentsLoading = 'pending';
			}
		},
		fetchSharedAppointmentsSucceed(state, action) {
			state.sharedAppointmentsLoading = 'idle';
			state.sharedAppointments = action.payload;
			state.filteredSharedAppointments = action.payload;
		},
		fetchSharedAppointmentsFailed(state, action) {
			if (state.sharedAppointmentsLoading === 'pending') {
				state.sharedAppointmentsLoading = 'idle';
				state.error = action.payload;
			}
		},

		fetchWaitingListAppointmentsLoading(state, action) {
			if (state.waitingListAppointmentsLoading === 'idle') {
				state.waitingListAppointmentsLoading = 'pending';
			}
		},
		fetchWaitingListAppointmentsSucceed(state, action) {
			state.waitingListAppointmentsLoading = 'idle';
			state.waitingListAppointments = action.payload;
			state.filteredWaitingListAppointments = action.payload;
		},
		fetchWaitingListAppointmentsFailed(state, action) {
			if (state.waitingListAppointmentsLoading === 'pending') {
				state.waitingListAppointmentsLoading = 'idle';
				state.error = action.payload;
			}
		},
		appointmentsListLoading(state, action) {
			if (state.appointmentsListLoading === 'idle') {
				state.appointmentsListLoading = 'pending';
			}
		},

		appointmentsListSucceed(state, action) {
			state.appointmentsListLoading = 'idle';
			state.appointmentsListData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		appointmentsListFailed(state, action) {
			if (state.appointmentsListLoading === 'pending') {
				state.appointmentsListLoading = 'idle';
				state.error = action.payload;
			}
		},
		searchAppointmentsSucceed(state, action) {
			state.filteredAppointments = getFilteredAppointments(
				state.appointments,
				action,
			);
		},

		searchSharedAppointmentsSucceed(state, action) {
			state.filteredSharedAppointments = getFilteredAppointments(
				state.sharedAppointments,
				action,
			);
		},

		searchWaitingListAppointmentsSucceed(state, action) {
			state.filteredWaitingListAppointments = getFilteredAppointments(
				state.waitingListAppointments,
				action,
			);
		},

		// * Waiting list
		waitingListLoading(state, action) {
			if (state.waitingListLoading === 'idle') {
				state.waitingListLoading = 'pending';
			}
		},

		waitingListSucceed(state, action) {
			state.waitingListLoading = 'idle';
			state.waitingListData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		waitingListFailed(state, action) {
			if (state.waitingListLoading === 'pending') {
				state.waitingListLoading = 'idle';
				state.error = action.payload;
			}
		},

		//TODO state[inputAppointments]
		resetAppointmentsSearch(state, action) {
			state.filteredAppointments = [...state.appointments];
			state.filteredWaitingListAppointments = [
				...state.waitingListAppointments,
			];
			state.filteredSharedAppointments = [...state.sharedAppointments];
		},

		// * Appointment other cases
		otherCaseAppointmentLoading(state, action) {
			if (state.otherCaseAppointmentsLoading === 'idle') {
				state.otherCaseAppointmentsLoading = 'pending';
			}
		},

		otherCaseAppointmentSucceed(state, action) {
			state.otherCaseAppointmentsLoading = 'idle';
			state.otherCaseAppointmentsData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		otherCaseAppointmentFailed(state, action) {
			if (state.otherCaseAppointmentsLoading === 'pending') {
				state.otherCaseAppointmentsLoading = 'idle';
				state.error = action.payload;
			}
		},
		loadJawTypesLoading(state, action) {
			if (state.loadJawTypesLoading === IDLE) {
				state.loadJawTypesLoading = PENDING;
			}
		},
		loadJawTypesSucceed(state, action) {
			state.loadJawTypesLoading = IDLE;
			state.jawTypes = action.payload;
		},
		loadJawTypesFailed(state, action) {
			if (state.loadJawTypesLoading === PENDING) {
				state.loadJawTypesLoading = IDLE;
				state.error = action.payload;
			}
		},

		// * create appointment
		createAppointmentLoading(state, action) {
			if (state.createAppointmentLoading === IDLE) {
				state.createAppointmentLoading = PENDING;
			}
		},
		createAppointmentSucceed(state, action) {
			state.createAppointmentLoading = IDLE;
			state.createAppointmentFinished = true;
			state.appointments = [...state.appointments, action.payload];
			state.filteredAppointments = [
				...state.filteredAppointments,
				action.payload,
			];
		},
		createAppointmentFailed(state, action) {
			if (state.createAppointmentLoading === PENDING) {
				state.createAppointmentLoading = IDLE;
				state.error = action.payload;
			}
		},
		createAppointmentConfirmed(state, action) {
			state.createAppointmentFinished = false;
		},

		// *Join account
		joinAccountRequestLoading(state, action) {
			if (state.joinAccountLoading === false) {
				state.joinAccountLoading = true;
			}
		},

		joinAccountRequestSucceed(state, action) {
			state.joinAccountLoading = false;
			state.joinAccountData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
			toastSuccess('Message Sent!');
		},

		joinAccountRequestFailed(state, action) {
			if (state.joinAccountLoading === true) {
				state.joinAccountLoading = false;
				state.error = action.payload;
			}
		},

		// * Price List
		priceListRequestLoading(state, action) {
			if (state.priceListLoading === false) {
				state.priceListLoading = true;
			}
		},

		priceListRequestSucceed(state, action) {
			state.priceListLoading = false;
			state.priceListData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		priceListRequestFailed(state, action) {
			if (state.priceListLoading === true) {
				state.priceListLoading = false;
				state.error = action.payload;
			}
		},
	},
});

export const {
	fetchAppointmentsLoading,
	fetchAppointmentsSucceed,
	fetchAppointmentsFailed,
	fetchSharedAppointmentsLoading,
	fetchSharedAppointmentsSucceed,
	fetchSharedAppointmentsFailed,
	fetchWaitingListAppointmentsLoading,
	fetchWaitingListAppointmentsSucceed,
	fetchWaitingListAppointmentsFailed,
	searchAppointmentsSucceed,
	searchSharedAppointmentsSucceed,
	searchWaitingListAppointmentsSucceed,
	resetAppointmentsSearch,
	loadSpicialistListLoading,
	loadSpicialistListSucceed,
	loadSpicialistListFailed,
	createAppointmentLoading,
	createAppointmentSucceed,
	createAppointmentFailed,
	appointmentsListLoading,
	appointmentsListSucceed,
	appointmentsListFailed,
	waitingListLoading,
	waitingListSucceed,
	waitingListFailed,
	otherCaseAppointmentLoading,
	otherCaseAppointmentSucceed,
	otherCaseAppointmentFailed,
	loadJawTypesLoading,
	loadJawTypesSucceed,
	loadJawTypesFailed,
	createAppointmentConfirmed,
	priceListRequestLoading,
	priceListRequestSucceed,
	priceListRequestFailed,
	joinAccountRequestLoading,
	joinAccountRequestSucceed,
	joinAccountRequestFailed,
} = doctorSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */
export const fetchDoctorAppointments = () => async (dispatch, getState) => {
	const doctorId = getState()?.global?.loggedInUser?.id || '';
	dispatch(fetchAppointmentsLoading());
	try {
		const appointments = await fetchNextDoctorAppointments(doctorId);
		dispatch(fetchAppointmentsSucceed(appointments));
	} catch (error) {
		dispatch(fetchAppointmentsFailed(error));
	}
};

export const fetchDoctorWaitingListAppointments =
	() => async (dispatch, getState) => {
		const doctorId = getState()?.global?.loggedInUser?.id || '';
		dispatch(fetchWaitingListAppointmentsLoading());
		try {
			let appointments = await fetchNextWaitingListAppointments(doctorId);

			//TODO - server should return empty array instead of null...
			if (appointments === null) {
				appointments = [];
			}
			dispatch(fetchWaitingListAppointmentsSucceed(appointments));
		} catch (error) {
			dispatch(fetchWaitingListAppointmentsFailed(error));
		}
	};

export const fetchDoctorSharedAppointments =
	() => async (dispatch, getState) => {
		const doctorId = getState()?.global?.loggedInUser?.id || '';
		dispatch(fetchSharedAppointmentsLoading());
		try {
			let appointments = await fetchNextSharedAppointments(doctorId);
			//TODO - server should return empty array instead of null...
			if (appointments === null) {
				appointments = [];
			}
			dispatch(fetchSharedAppointmentsSucceed(appointments));
		} catch (error) {
			dispatch(fetchSharedAppointmentsFailed(error));
		}
	};

//@TODO - fetch from server - pagination
export const searchAppointments =
	(dateOfBirth, patientName) => async (dispatch) => {
		dispatch(searchAppointmentsSucceed({ dateOfBirth, patientName }));
	};

//@TODO - fetch from server - pagination
export const searchSharedAppointments =
	(dateOfBirth, patientName) => async (dispatch) => {
		dispatch(searchSharedAppointmentsSucceed({ dateOfBirth, patientName }));
	};

//@TODO - fetch from server - pagination
export const searchWaitingListAppointments =
	(dateOfBirth, patientName) => async (dispatch) => {
		dispatch(
			searchWaitingListAppointmentsSucceed({ dateOfBirth, patientName }),
		);
	};

//Helper functions TODO: extract to service
function getFilteredAppointments(appointments, action) {
	return appointments.filter((appointment) => {
		return (
			(appointment.patient_full_name
				.toLowerCase()
				.includes(action.payload.patientName.toLowerCase()) ||
				action.payload.patientName === '') &&
			((appointment.appointment_date != '0000-00-00 00:00:00' &&
				moment(appointment.appointment_date).format('YYYY-MM-DD') ===
					action.payload.dateOfBirth) ||
				action.payload.dateOfBirth === null)
		);
	});
}
export const loadAllSpecialist = () => async (dispatch) => {
	dispatch(loadSpicialistListLoading());
	try {
		const specialtiesList = await specialitiesList();
		dispatch(loadSpicialistListSucceed(specialtiesList));
	} catch (error) {
		dispatch(loadSpicialistListFailed(error));
	}
};
export const appointmentAndScanList =
	(requestParam) => async (dispatch, getState) => {
		//const doctorId = getState().global.loggedInUser.id;
		dispatch(appointmentsListLoading());
		try {
			// var appointments = [];
			var appointments = await appoinmentAndScanList(requestParam);

			dispatch(appointmentsListSucceed(appointments));
		} catch (error) {
			dispatch(appointmentsListFailed(error));
		}
	};
export const selectAppointments = (state) => state.doctors.filteredAppointments;
export const selectSharedAppointments = (state) =>
	state.doctors.filteredSharedAppointments;
export const selectWaitingListAppointments = (state) =>
	state.doctors.filteredWaitingListAppointments;

export const selectAppointmentsLoading = (state) =>
	state.doctors.appointmentsLoading;
export const selectSharedAppointmentsLoading = (state) =>
	state.doctors.sharedAppointmentsLoading;
export const selectWaitingListAppointmentsLoading = (state) =>
	state.doctors.waitingListAppointments;

////////////////////////////
export const selectSpecialist = (state) => state.doctors.specialtiesListData;
export const selectSpecialistLoading = (state) =>
	state.doctors.specialtiesListLoading;
///////////////////////////////////
export const getAppointmentAndScanList = (state) =>
	state.doctors.appointmentsListData;
export const getAppointmentAndScanListLoading = (state) =>
	state.doctors.appointmentsListLoading;

// * Waiting List
export const fetchWaitingList = (requestParam) => async (dispatch) => {
	dispatch(waitingListLoading());
	try {
		const response = await waitingList(requestParam);
		dispatch(waitingListSucceed(response));
	} catch (error) {
		dispatch(waitingListFailed(error));
	}
};
export const getWaitingListLoading = (state) =>
	state.doctors.waitingListLoading;
export const getWaitingList = (state) =>
	state?.doctors?.waitingListData?.data?.responseData?.listData || [];
export const getWaitingPaginationData = (state) =>
	state?.doctors?.waitingListData?.data?.responseData || null;

// * Appointment other cases
export const fetchOtherCaseAppointments =
	(requestParam) => async (dispatch) => {
		dispatch(otherCaseAppointmentLoading());
		try {
			const response = await otherCasesAppointments(requestParam);
			dispatch(otherCaseAppointmentSucceed(response));
		} catch (error) {
			dispatch(otherCaseAppointmentFailed(error));
		}
	};
export const getOtherCaseAppointmentsLoading = (state) =>
	state.doctors.otherCaseAppointmentsLoading;
export const getOtherCaseAppointments = (state) =>
	state?.doctors?.otherCaseAppointmentsData?.data?.responseData || [];

export const loadJawTypes = () => async (dispatch, getState) => {
	dispatch(loadJawTypesLoading());
	try {
		const jaws = await fetchJawTypes();
		dispatch(loadJawTypesSucceed(jaws));
	} catch (error) {
		dispatch(loadJawTypesFailed(error));
	}
};
// * Post join account request
export const joinAccount = (requestParam) => async (dispatch) => {
	dispatch(joinAccountRequestLoading());
	try {
		const response = await postJoinAccountRequest(requestParam);
		dispatch(joinAccountRequestSucceed(response));
	} catch (error) {
		dispatch(joinAccountRequestFailed(error));
	}
};
export const isJoinAccountLoading = (state) => state.doctors.joinAccountLoading;
export const getJoinAccountResponseCode = (state) =>
	state?.doctors?.joinAccountData?.data?.responseCode || null;

// * Get price list from from backend
export const getPriceList = (requestParam) => async (dispatch) => {
	dispatch(priceListRequestLoading());
	try {
		const response = await fetchPriceList(requestParam);
		dispatch(priceListRequestSucceed(response));
	} catch (error) {
		dispatch(priceListRequestFailed(error));
	}
};
export const isPriceListLoading = (state) => state.doctors.priceListLoading;
export const getPriceListResponse = (state) =>
	state?.doctors?.priceListData?.data?.responseData || [];

/**
 * select calendar events correlated to appointments
 * filter deleted and archived appointments
 */
//TODO - handled deleted/archived appointments
export const selectAppointmentsEvents = (state) =>
	state.doctors.filteredAppointments
		.filter(
			(appointment) =>
				![79, 80, 81, 97, 113, 134, 168, 206].includes(appointment.status_id),
		)
		.map((appointment) => createCalendarEventFromAppointment(appointment));

export const selectJawTypes = (state) => state.doctors.jawTypes;

export const doCreateAppointment = (formData) => async (dispatch) => {
	dispatch(createAppointmentLoading());
	try {
		const response = await createAppointment(formData);
		dispatch(createAppointmentSucceed(response));
	} catch (error) {
		dispatch(createAppointmentFailed(error));
	}
};
export const selectCreateAppointmentFinished = (state) =>
	state.doctors.createAppointmentFinished;
export const selectCreateAppointmentLoading = (state) =>
	state.doctors.createAppointmentLoading;

export default doctorSlice.reducer;
