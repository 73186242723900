export const DIAGNOCAT_PATIENTS = '/patients';

export const REACT_APP_API_URL = process.env.REACT_APP_API_ENDPOINT;

export const CTDENT_API_KEY =
	'7l6J7u9MeJ91gV35MbbnR2FGCbh2Cof+CnWnYFyvVjg+pV1K5NWiu5FEvOpIV';

export const CTDENT_DIAGNOCAT_SAVE_LINKS = 'diagnocat/appointment/savelinks';
export const CTDENT_DIAGNOCAT_SAVE_PDFS = 'diagnocat/appointment/savepdfs';
export const CTDENT_DIAGNOCAT_SAVE_LINK = 'diagnocat/appointment/savelink';
export const CTDENT_DIAGNOCAT_GET_PDF_TYPES = 'diagnocat/pdf/types';
export const CTDENT_GET_USERS_BY_ROLE = 'user/role/getusersbyrole';

export const CTDENT_GET_ALL_APPOINTMENTS_BY_PARAMETERS = '/appointment/all-new';
export const CTDENT_GET_APPOINTMENT = 'appointment/get';
export const CTDENT_GET_APPOINTMENT_OTHER_CASE = 'appointment/other-cases';

export const CTDENT_AUTH = 'user/login';
export const CTDENT_DOCTOR_AUTH = 'doctor/login';
export const CTDENT_AUTH_BYID = 'doctor/get';
/** */
export const SPECIALITIES_URL = 'doctor/specialities';
export const BRANCHLIST_URL = '/branch/all';
export const CTDENT_BRANCH_ALL = 'branch/all';

export const DOCTOR_SIGNUP = 'doctor/create';
export const FORGOT_PASSWORD = 'doctor/forgot-password';
export const RESET_PASSWORD = 'doctor/reset-password';
export const GET_DOCTOR_DETAILS = 'doctor/get';
export const EMAIL_VERIFY = 'doctor/verify-email';
export const THANKYOU_DETAILS = 'page/thankyou';
export const EDIT_DOCTOR = 'doctor/update';
export const UPDATE_DOCTOR_PRODUCT_MODAL = 'doctor/update-product-tour';
export const UPDATE_DOCTOR_SIGNATURE = 'doctor/save-signature';
export const SAVE_DOCTOR_PRACTICE_DETAIL = 'doctor/save-doctors-practice-details';
export const SAVE_DOCTOR_AGREEMENT_PERSON = 'doctor/save-doctors-agrement-persons';
export const GET_DOCTOR_AGREEMENT_PERSON = 'doctor/get-doctors-agrement-persons';
export const DOCTOR_APPOINMENT_LIST = 'appointment/all-new';
export const DOCTOR_WAITING_LIST = 'docotr/waiting-list';
export const FORMATS_JAWSCANS = 'formats/jawscans';
export const FORMATS_APPOINTMENT = 'formats/appointment';
export const APPOINMENT_BY_ID = 'appointment/get';
export const APPOINMENT_SEARCH = 'appointment/search';
export const GET_APPOINTMENTS_STATUSES = 'appointments-statuses/get';
export const DOCTOR_INVOICE_LIST = 'invoice';
export const DOCTOR_PRICE_LIST = 'doctor/pricelist';
export const DOCTOR_PRICE_LIST_UPDATED = 'doctor/pricelist-new';
export const CTDENT_APPOINTMENT_GET_UPLOADED_FILE = 'appointment/get-ctdent-uploaded-files';
export const CTDENT_APPOINTMENT_REMOVE_UPLOADED_FILE = 'appointment/remove-ctdent-uploaded-file';
export const CTDENT_POST_JOIN_ACCOUNT = 'account/join-accounts';
export const CTDENT_POST_ORDER_REPORT = 'doctor/saveOrder';
export const DOCTOR_PATIENT_LIST = 'doctor/doctorAppointments';
export const ORDER_REPORT_CALCULATE_PRICE = 'doctor/calculateReportPrice';
export const CTDENT_SUPPORT_REPORT_REQUEST = 'system/support-report-request';
export const CTDENT_CALENDAR_SLOTS = 'doctor/getCalendarSlots';
export const CTDENT_CONNECTED_ACCOUNT = 'doctor/getConnectedAccounts';
export const CTDENT_APPOINTMENT_CREATE = 'appointment/create';
export const CTDENT_APPOINTMENT_UPLOAD_FILE =
	'appointment/upload-appointment-file';
export const CTDENT_APPOINTMENT_GET = 'appointment/get';
export const CTDENT_APPOINTMENT_ARCHIVE = 'appointment/archive-appointment';
export const CTDENT_APPOINTMENT_UPDATE = 'appointment/update';
export const CTDENT_APPOINTMENT_DATE_UPDATE = 'appointment/update-appointment-date';
export const CTDENT_APPOINTMENT_PRICE_UPDATE = 'appointment/update-appointment-price';
export const CTDENT_APPOINTMENT_REGENERATE_INVOICE = 'appointment/regenerate-appointment-invoice';
export const CTDENT_APPOINTMENT_PAYMENT_UPDATE = 'appointment/update-appointment-payment';
export const CTDENT_APPOINTMENT_SEND_EMAIL = 'appointment/send-scan-to-patient';
export const CTDENT_APPOINTMENT_SHARE = 'appointment/share-appointment';
export const CTDENT_APPOINTMENT_SERVICE =
	'appointment/get-new-service-price-list';
export const CTDENT_APPOINTMENT_PRICE_BREAKDOWN =
	'doctor/calculateEstimatedPriceForReferral';
export const CTDENT_APPOINTMENT_SUCCESS_UPDATE =
	'appointment/thank-you-page-email';
export const CTDENT_PARTNER_APPOINTMENT_STATUS_UPDATE =
	'appointment/partner-change-status';
export const CTDENT_GET_BRANCH_EMAIL = 'common/get-booking-requests-email';
export const CTDENT_SAVE_BILLING_DETAILS = 'doctor/save-payment-details';
export const CTDENT_SAVE_BILLING_INVOICE_EMAIL_DETAILS = 'stripe/update-stripe-customer-email';
export const CTDENT_SAVE_BILLING_ADDRESS_DETAILS = 'stripe/update-stripe-customer-address';
export const CTDENT_GET_DOCTOR_DETAIL_BY_ID =
	'priceanddiscount/get-doctor-details';
export const CTDENT_GET_DOCTOR_GENERAL_DISCOUNT_LIST =
	'priceanddiscount/show-doctor-general-discount';
export const CTDENT_UPDATE_DOCTOR_GENERAL_DISCOUNT =
	'priceanddiscount/update-doctor-general-discount';
export const CTDENT_GET_DOCTOR_SPECIAL_DISCOUNT_LIST =
	'priceanddiscount/get-all-special-discount';

export const CTDENT_UPDATE_DOCTOR_SPECIAL_DISCOUNT =
	'priceanddiscount/update-doctor-special-discount';
export const CTDENT_DELETE_DOCTOR_SPECIAL_DISCOUNT =
	'priceanddiscount/delete-doctor-special-discount';
export const CTDENT_ADD_DOCTOR_SPECIAL_DISCOUNT =
	'priceanddiscount/add-doctor-special-discount';
export const CTDENT_GET_PRICE_LIST = 'priceanddiscount/get-price-list';

export const CTDENT_STRIPE_SAVE_CARD = 'stripe/payment-method-attach';
export const CTDENT_STRIPE_INVOICES = 'stripe/get-invoices';
export const CTDENT_STRIPE_DEBIT_BALANCE = 'stripe/get-debit-invoice-balance';
export const DOCTOR_STRIPE_INVOICE_DETAIL = 'stripe/get-invoice-by-id';

export const CTDENT_DOCTOR_SPECIAL_DISCOUNT_API = 'priceanddiscount/special-discounts';
export const CTDENT_DOCTOR_COMBO_DISCOUNT_API = 'priceanddiscount/combo-discounts';
export const CTDENT_DOCTOR_MASTER_PRICE_LIST_API = 'priceanddiscount/master-price-list';
export const CTDENT_ROOT_SALES_REP_REQUEST_APPROVALS_API = 'common/get-sale-rep-pending-doctors-list';
export const CTDENT_ROOT_UDPATE_SALES_REP_REQUEST_APPROVALS_API = 'common/save-sales-rep-user-and-status';
export const CTDENT_ROOT_SAVE_INVISIBLE_SHARE = 'common/save-invisible-share';
export const CTDENT_ROOT_DETAILED_REPORT = 'report/detailed-report';
export const CTDENT_ROOT_SAVE_PARTNERS_ACCOUNTS = 'common/save-partners-accounts';
export const CTDENT_PARTNER_CREATE_REPORT = 'partner/create-partner-report';
export const CTDENT_PARTNER_GET_REPORT = 'partner/get-partner-report';
/** */