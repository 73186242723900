import {
	BRANCHLIST_URL,
	CTDENT_API_KEY,
	CTDENT_AUTH,
	CTDENT_DOCTOR_AUTH,
	SPECIALITIES_URL,
	DOCTOR_SIGNUP,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	GET_DOCTOR_DETAILS,
	EMAIL_VERIFY,
	THANKYOU_DETAILS,
	EDIT_DOCTOR,
	DOCTOR_APPOINMENT_LIST,
	DOCTOR_WAITING_LIST,
	FORMATS_JAWSCANS,
	FORMATS_APPOINTMENT,
	UPDATE_DOCTOR_PRODUCT_MODAL,
	UPDATE_DOCTOR_SIGNATURE,
	SAVE_DOCTOR_AGREEMENT_PERSON,
	GET_DOCTOR_AGREEMENT_PERSON,
	SAVE_DOCTOR_PRACTICE_DETAIL,
} from '../assets/conf/api-endpoints';
import {
	generateHeaderToken,
	getHeaderTimestamp,
	getLocalStorage,
	getNonce,
} from '../utils/globals.utils';
import ctdentInstance from '../axios/ctdentAxios';
import cryptoJs from 'crypto-js';

export async function authUser(username, password) {
	const request = {
		api_key: CTDENT_API_KEY,
		username: username,
		password: password,
	};
	const response = await ctdentInstance.post(CTDENT_AUTH, request, apiConfig());
	return response;
}

export async function signUpDoctor(requestParam) {
	const response = await ctdentInstance.post(
		DOCTOR_SIGNUP,
		{
			...requestParam,
			password: requestParam?.password ? cryptoJs.AES.encrypt(requestParam?.password, process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY).toString() : null,
			confirmPassword: requestParam?.confirmPassword ? cryptoJs.AES.encrypt(requestParam?.confirmPassword, process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY).toString() : null
		},
		apiConfig(),
	);
	return response;
}
export async function editDoctorProfile(requestParam) {
	const response = await ctdentInstance.post(
		EDIT_DOCTOR,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function updateDoctorProductModal(requestParam) {
	const response = await ctdentInstance.post(
		UPDATE_DOCTOR_PRODUCT_MODAL,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function updateDoctorSignature(requestParam) {
	const response = await ctdentInstance.post(
		UPDATE_DOCTOR_SIGNATURE,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function saveDoctorPracticeDetail(requestParam) {
	const response = await ctdentInstance.post(
		SAVE_DOCTOR_PRACTICE_DETAIL,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function saveDoctorAgreementPerson(requestParam) {
	const response = await ctdentInstance.post(
		SAVE_DOCTOR_AGREEMENT_PERSON,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function getDoctorAgreementPerson(doctorId) {
	const response = await ctdentInstance.get(
		GET_DOCTOR_AGREEMENT_PERSON+'?doctor_id='+doctorId,
		apiConfig(),
	);
	return response;
}
export async function forgotPassword(requestParam) {
	const response = await ctdentInstance.post(
		FORGOT_PASSWORD,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function resetPassword(requestParam) {
	const response = await ctdentInstance.post(
		RESET_PASSWORD,
		{
			...requestParam,
			password: requestParam?.password ? cryptoJs.AES.encrypt(requestParam?.password, process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY).toString() : null,
			confirmPassword: requestParam?.confirmPassword ? cryptoJs.AES.encrypt(requestParam?.confirmPassword, process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY).toString() : null
		},
		apiConfig(),
	);
	return response;
}
export async function authUserById(id) {
	const request = {
		api_key: CTDENT_API_KEY,
		id: id,
	};
	const response = await ctdentInstance.post('doctor/get', request);
	return response;
}

export async function getUserInfo(userId) {
	const response = await ctdentInstance.get(
		THANKYOU_DETAILS + '?id=' + userId,
		apiConfig(),
	);
	return response;
}
export async function authDoctor(username, password) {
	//auth the doctor
	const request = {
		api_key: CTDENT_API_KEY,
		email: username?.trim()?.toLowerCase(),
		password: cryptoJs.AES.encrypt(password, process.env.REACT_APP_CRYPTO_ENCRYPTION_KEY).toString(),
	};
	const response = await ctdentInstance.post(
		CTDENT_DOCTOR_AUTH,
		request,
		apiConfig(),
	);
	return response;
}

export async function specialitiesList() {
	const response = await ctdentInstance.post(SPECIALITIES_URL, {}, apiConfig());
	return response;
}
export async function appoinmentAndScanList(requestParam) {
	const response = await ctdentInstance.post(
		DOCTOR_APPOINMENT_LIST,
		requestParam,
		apiConfig(),
	);
	return response;
}
export async function branchList() {
	const response = await ctdentInstance.post(BRANCHLIST_URL, {}, apiConfig());
	return response;
}
export async function getProfileDetails() {
	const response = await ctdentInstance.get(GET_DOCTOR_DETAILS, apiConfig());
	return response;
}
export async function emailVerification(emailRequest) {
	const response = await ctdentInstance.post(
		EMAIL_VERIFY,
		emailRequest,
		apiConfig(),
	);
	return response;
}

// * Waiting List
export async function waitingList(requestParam) {
	const reqConfig = apiConfig();
	const response = await ctdentInstance.get(DOCTOR_WAITING_LIST, {
		params: requestParam,
		headers: reqConfig?.headers,
		method: reqConfig?.method,
	});
	return response;
}

export const apiConfig = () => {
	const accessToken = getLocalStorage('authToken') || '';
	const tempAccessToken = getLocalStorage('tempAuthToken') || '';
	const appConfigData = {
		method: 'PUT,DELETE,POST,GET,OPTION',
		headers: {
			nonce: getNonce(),
			timestamp: getHeaderTimestamp(),
			token: generateHeaderToken(),
		},
	};
	if (accessToken || tempAccessToken)
		appConfigData.headers.accesstoken = accessToken || tempAccessToken;

	return appConfigData;
};
